import React from "react";
import { useFormikContext } from "formik";
import { Dropdown } from "./Dropdown";
import { deepGet } from "./deepObjUtils";
import { ErrorM } from "./ErrorM";

export type SelectOption = { value: any; label: string };

export interface FormSingleSelectFieldProps<T> {
  fieldName: keyof T & string;
  options: SelectOption[];
  label: string;
  readOnly?: boolean;
  dropdownProps?: any;
}

const FormMultiSelectField = function <T>(
  props: FormSingleSelectFieldProps<T>
) {
  const { fieldName, label, options, readOnly, dropdownProps } = props;
  const formik = useFormikContext<T>();

  const handleChange = (e: SelectOption) => {
    if (e != null) {
      if (Array.isArray(e)) {
        formik.setFieldValue(
          fieldName,
          e.map((e) => e.value)
        );
      } else {
        formik.setFieldValue(fieldName, [e.value]);
      }
    } else {
      formik.setFieldValue(fieldName, []);
    }
  };

  const getOptionFromValue = (value: number | string) =>
    options.find((o) => o.value === value);

  const fieldValues = deepGet(formik.values, fieldName);

  const value = Array.isArray(fieldValues)
    ? fieldValues.map(getOptionFromValue).filter((v) => v != null)
    : getOptionFromValue(fieldValues);

  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">{label}</div>
      <div className="FormField">
        <Dropdown
          isMulti
          onChange={handleChange}
          options={options}
          value={(value as any) ?? null}
          roundedRectangle
          readOnly={readOnly}
          dropdownProps={dropdownProps}
        />
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormMultiSelectField };
