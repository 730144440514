import React from "react";
import { ErrorM } from "./ErrorM";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useFormikContext, useField } from "formik";
import getUserLocale from "get-user-locale";

export interface FormDateTimeFieldProps<T> {
  fieldName: keyof T;
  label: string;
  readOnly?: boolean;
  onChange?: (
    date: Date | null,
    event: React.SyntheticEvent<any> | undefined
  ) => void;
  datePickerProps?: Omit<ReactDatePickerProps, "onChange">;
}

const FormDateTimeField = function<T>({
  fieldName,
  label,
  readOnly,
  datePickerProps,
  onChange,
}: FormDateTimeFieldProps<T>) {
  const [field] = useField(fieldName as string);
  const formik = useFormikContext();

  const handleChange = (date: Date | null) => {
    formik.setFieldValue(fieldName as string, date);
  };

  const selectedDate = field.value ? new Date(field.value) : null;
  const locale = getUserLocale();

  return (
    <div className="FormBox FormSideBySide">
      <div className="FormLabel">
        <label>{label}</label>
      </div>
      <div className="FormField">
        <DatePicker
          dateFormat="P"
          locale={locale}
          className={readOnly ? "EditBox ViewOnly" : "EditBox"}
          onChange={onChange ? onChange : handleChange}
          selected={selectedDate}
          readOnly={readOnly}
          placeholderText={readOnly ? "" : "Select date..."}
          {...datePickerProps}
        />
        <ErrorM name={fieldName.toString()} />
      </div>
    </div>
  );
};

export { FormDateTimeField };
