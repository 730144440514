/**
 * @example
 * field types:
 *
 * number/integer
 * text/string
 * textarea
 * boolean
 * datetime
 * multiselect
 * singleselect
 */

import { FormField } from "models";

export const toCamelCase = function<T>(pascalCase: keyof T): keyof T {
  const firstLetter = (pascalCase as string).slice(0, 1);
  const remaining = (pascalCase as string).slice(
    1,
    (pascalCase as string).length
  );
  return (firstLetter.toLowerCase() + remaining) as keyof T;
};

export const formatFormFieldNames = function<T>(
  metaData: FormField<T>[]
): FormField<T>[] {
  return metaData.map(m => ({ ...m, name: toCamelCase<T>(m.name) }));
};
