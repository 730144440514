import React from "react";
import { useFormikContext, FormikContextType } from "formik";
import { deepGet } from "./deepObjUtils";
import { Input } from "./Input";
import { ErrorM } from "./ErrorM";

export interface FormBooleanFieldProps {
  fieldName: string;
  label: string;
  readOnly?: boolean;
}

const FormBooleanField = function<T>({
  fieldName,
  label,
  readOnly,
}: FormBooleanFieldProps) {
  const formikContext = useFormikContext<FormikContextType<T>>();
  // console.log(formikContext);
  if (readOnly) {
    return (
      <div className="FormBox FormSideBySide">
        <div className="FormLabel">
          <label>{label}</label>
        </div>

        <div className="FormField">
          <Input
            name={fieldName}
            className={"EditBox ViewOnly"}
            readOnly={readOnly}
            value={deepGet(formikContext.values, fieldName) ? "Yes" : "No"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="FormBox FormSideBySide">
        <div className="FormLabel NoPaddingTop">
          <label>{label}</label>
        </div>

        <div className="RadioButtons">
          <div
            className="Radio"
            role="checkbox"
            aria-checked={deepGet(formikContext.values, fieldName)}
            onClick={() => formikContext.setFieldValue(fieldName as any, true)}
          >
            <input
              id={fieldName + "-yes"}
              type="radio"
              name={fieldName}
              value={"true"}
              checked={deepGet(formikContext.values, fieldName)}
              onChange={() => {}} // Prevent console error about providing a checked attribute but no onChange prop. The onChange is the onClick on the div that wraps this.
            />
            <label className="CustomInput" htmlFor={fieldName + "-yes"}>
              Yes
            </label>
          </div>
          <div
            className="Radio"
            role="checkbox"
            aria-checked={deepGet(formikContext.values, fieldName)}
            onClick={() => formikContext.setFieldValue(fieldName as any, false)}
          >
            <input
              id={fieldName + "-no"}
              type="radio"
              name={fieldName}
              value={"false"}
              checked={!deepGet(formikContext.values, fieldName)}
              onChange={() => {}} // Prevent console error about providing a checked attribute but no onChange prop. The onChange is the onClick on the div that wraps this.
            />
            <label className="CustomInput" htmlFor={fieldName + "-no"}>
              No
            </label>
          </div>
        </div>
        <ErrorM name={fieldName.toString()} />
      </div>
    );
  }
};

export { FormBooleanField };
