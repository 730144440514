import React from "react";
import { FormField } from "./models";
import { FormTextField } from "./FormTextField";
import { FormBooleanField } from "./FormBooleanField";
import { formatFormFieldNames, toCamelCase } from "./formatFormFieldNames";
import { FormSingleSelectField } from "./FormSingleSelectField";
import { FormMultiSelectField } from "./FormMultiSelectField";
import { useFormikContext } from "formik";
import { FormDateTimeField } from "./FormDateTimeField";
import { FormTextAreaField } from "./FormTextAreaField";

/** Avamae standard API response structure */
export type ErrorMessage = {
  type: any;
  fieldName: string;
  messageText: string;
};

export interface ApiResponse<T = any, M = any> {
  id: number;
  details: M;
  metadata: FormField<T>[];
  status: "0" | "1";
  errors: ErrorMessage[];
}

/** Begin Form Builder */

type Renderer<T> = (
  key: number | string,
  field: FormField<T>
) => React.ReactNode;

export type FieldRenderers<T> = {
  [k: string]: Renderer<T>;
};

type HiddenFields<T> = {
  [P in keyof T]?: boolean;
};

type FormFieldRendererOverride<T> = {
  [P in keyof T]?: Renderer<T>;
};

export interface FormBuilderProps<T> {
  loadingFallback: React.ReactNode;
  fieldRenderers?: FieldRenderers<T>;
  overrideFormFieldRenderer?: FormFieldRendererOverride<T>;
  data: ApiResponse<T>;
  formValidationErrors?: ErrorMessage[];
  readOnly?: boolean;
  readOnlyFields?: { [k: string]: boolean };
  fieldProps?: { [k: string]: any };
  additionalData?: any;
  hiddenFields?: HiddenFields<T>;
}

// Form builder main component

const FormBuilder = function<T>(props: FormBuilderProps<T>) {
  const {
    fieldRenderers,
    overrideFormFieldRenderer,
    data,
    loadingFallback,
    formValidationErrors,
    readOnly,
    additionalData,
    hiddenFields,
    readOnlyFields,
    fieldProps,
  } = props;
  const formikActions = useFormikContext();

  const { setErrors } = formikActions;
  React.useEffect(() => {
    if (formValidationErrors && formValidationErrors.length > 0) {
      let errors: Record<string, string> = {};
      formValidationErrors.forEach(err => {
        errors[toCamelCase(err.fieldName)] = err.messageText;
      });
      setErrors(errors);
    }
  }, [formValidationErrors, setErrors]);

  const defaultRenderers: FieldRenderers<T> = {
    integer: (key, field) => (
      <FormTextField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
        inputProps={
          fieldProps
            ? { type: "number", ...fieldProps[field.name as string] }
            : { type: "number" }
        }
      />
    ),
    number: (key, field) => (
      <FormTextField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
        inputProps={
          fieldProps
            ? { type: "number", ...fieldProps[field.name as string] }
            : { type: "number" }
        }
      />
    ),
    text: (key, field) => (
      <FormTextField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
        inputProps={fieldProps ? fieldProps[field.name as string] : undefined}
      />
    ),
    string: (key, field) => (
      <FormTextField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
        inputProps={fieldProps ? fieldProps[field.name as string] : undefined}
      />
    ),
    textarea: (key, field) => (
      <FormTextAreaField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
        textAreaProps={
          fieldProps ? fieldProps[field.name as string] : undefined
        }
      />
    ),
    boolean: (key, field) => (
      <FormBooleanField
        key={key}
        fieldName={field.name.toString()}
        label={field.label}
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
      />
    ),
    datetime: (key, field) => (
      <FormDateTimeField
        key={key}
        fieldName={field.name}
        label={field.label.toString()}
        datePickerProps={
          fieldProps ? fieldProps[field.name as string] : undefined
        }
        readOnly={
          readOnly ||
          (readOnlyFields ? readOnlyFields[field.name as string] : false)
        }
      />
    ),
    multiselect: (key, field) => {
      if (!additionalData) {
        throw new Error(
          `You have a "MultiSelect" field type for field ${field.name} but provided no options for the dropdown. This was expected to be in the additionalData prop for FormBuilder.`
        );
      }
      const options = additionalData[field.name];
      if (!options) {
        throw new Error(
          `No options found for MultiSelect field ${field.name}. This was expected to be found in the additionalData prop for FormBuilder`
        );
      }
      return (
        <FormMultiSelectField
          key={key}
          fieldName={field.name.toString()}
          options={options}
          label={field.label}
          readOnly={
            readOnly ||
            (readOnlyFields ? readOnlyFields[field.name as string] : false)
          }
          dropdownProps={
            fieldProps ? fieldProps[field.name as string] : undefined
          }
        />
      );
    },
    singleselect: (key, field) => {
      if (!additionalData) {
        throw new Error(
          `You have a "SingleSelect" field type for field ${field.name} but provided no options for the dropdown. This was expected to be in the additionalData prop for FormBuilder.`
        );
      }
      const options = additionalData[field.name];
      if (!options) {
        throw new Error(
          `No options found for single select field ${field.name}. This was expected to be found in the additionalData prop for FormBuilder`
        );
      }
      return (
        <FormSingleSelectField
          key={key}
          fieldName={field.name.toString()}
          options={options}
          label={field.label}
          readOnly={
            readOnly ||
            (readOnlyFields ? readOnlyFields[field.name as string] : false)
          }
          dropdownProps={
            fieldProps ? fieldProps[field.name as string] : undefined
          }
        />
      );
    },
  };

  // Renderers
  const renderers: FieldRenderers<T> = {
    ...defaultRenderers,
    ...fieldRenderers,
  };

  if (data) {
    const { metadata } = data;
    if (!metadata) {
      throw new Error(`No metadata field found on the provided data`);
    }
    const formFields = formatFormFieldNames<T>(metadata);
    const form =
      formFields &&
      formFields
        .filter(field => {
          if (hiddenFields) {
            if (hiddenFields[field.name]) {
              return false;
            }
          }
          return field.type.toLowerCase() !== "hidden";
        })
        .map((field, i) => {
          if (overrideFormFieldRenderer) {
            const rendererOverride = overrideFormFieldRenderer[field.name];
            if (rendererOverride) {
              return (
                <React.Fragment key={field.name.toString()}>
                  {rendererOverride(i, field)}
                </React.Fragment>
              );
            }
          }
          const renderer: Renderer<T> =
            renderers[field.type.toLowerCase()] ??
            ((i, field) => (
              <p key={i}>
                Renderer for field type <strong>{field.type}</strong> is not
                defined
              </p>
            ));

          return (
            <React.Fragment key={field.name.toString()}>
              {renderer(i, field)}
            </React.Fragment>
          );
        });
    return <>{form}</>;
  } else {
    return <>{loadingFallback}</>;
  }
};

export { FormBuilder };
